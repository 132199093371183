import React, { useState } from 'react'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeart,
  faQuoteRight,
  faQuoteLeft,
  faAward
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import PaymentModal from '../../components/PaymentModal'
import BackToTopButton from '../../components/BackToTopButton'
import Link from '../../components/LinkWrapper'
import ScrollOnAnimation from '../../components/ScrollOnAnimation'
import i18n from '../../locales/he.yaml'
import { prettyPrice } from '../../utils'

const course = i18n.courses.nonAcademyCourses.find(
  (course) => course.name === 'מעוגה יפה לעוגה שאי אפשר להתעלם ממנה'
)

const WowQuery = graphql`
  query WowQuery {
    image1: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-הדמויות-אריה-דובי-דינוזאור-אפרוח-ארנב-שמפוסלים-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    image2: file(
      relativePath: {
        eq: "אריה-דובי-ארנב-דינוזאור-ואפרוח-מפוסלים-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image3: file(relativePath: { eq: "דינוזאור-נאיבי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image4: file(relativePath: { eq: "דובי-נאיבי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image5: file(relativePath: { eq: "ארנב-נאיבי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image6: file(relativePath: { eq: "אריה-נאיבי-2-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image7: file(relativePath: { eq: "אפרוח-נאיבי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const CakesYouCannotIgnoreTemplate = ({ pathname, data }) => {
  const [isPaymentForm, setIsPaymentForm] = useState(false)

  const { image1, image2, image3, image4, image5, image6, image7, survival } =
    data

  const HOW_IT_WORKS_IMAGE = [image3, image7]

  return (
    <>
      {isPaymentForm && (
        <PaymentModal
          onClose={() => setIsPaymentForm(false)}
          welcomeImage={image2}
          successLabel={course.successLabel}
          course={course}
        />
      )}
      <BackToTopButton selectorId='cakes-you-cannot-ignore' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {course.name}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column is-8 is-full-touch'>
                <h2 className='is-size-4 has-text-nude'>{course.subtitle}</h2>
                <h1 className='is-size-4'>{course.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='columns is-multiline is-mobile is-centered'>
              <div className='column is-10-mobile is-8-tablet is-5-desktop'>
                <GatsbyImage
                  image={getImage(image1)}
                  title={course.mainImage.title}
                  alt={course.mainImage.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(image1).width,
                    margin: '0 auto' // used to center the image
                  }}
                  loading='eager'
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {course.mainImage.title}
                  </p>
                </div>
              </div>
              <div className='column is-6-desktop is-full-tablet is-full-mobile is-offset-1-desktop'>
                <h3 className='is-size-5'>{course.teaser}</h3>
                <p>{course.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body is-padding-bottom-0 is-padding-top-0'>
          <hr className='has-background-nude-light' />
        </div>
      </div>
      <div id='cakes-you-cannot-ignore' className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <ScrollOnAnimation>
              <div className='has-text-centered is-margin-bottom-6'>
                <h3 className='has-text-nude'>{course.plan.title}</h3>
              </div>
            </ScrollOnAnimation>
            <div className='extras-item'>
              <ScrollOnAnimation>
                <div className='has-text-centered is-margin-bottom-6'>
                  <h4>{course.plan.abc.title}</h4>
                  <p>{course.plan.abc.description}</p>
                </div>
              </ScrollOnAnimation>
              <div className='columns is-multiline is-mobile is-centered is-vcentered'>
                <ScrollOnAnimation>
                  <div className='column is-6-desktop is-full-tablet'>
                    <div className='accordion is-fixed-on-open'>
                      <p>{course.plan.abc.prefix}</p>
                      <input type='checkbox' id='abc' hidden />
                      <label
                        className='accordion-header is-margin-left-3'
                        htmlFor='abc'
                      >
                        <a className='button is-text has-text-weight-bold'>
                          {course.plan.expand}
                        </a>
                      </label>
                      {course.plan.abc.bullets.map((bullet) => (
                        <div key={bullet.title} className='is-relative'>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className='has-text-nude is-size-7 is-absolute'
                            style={{ top: 8 }}
                          />
                          <div className='is-margin-left-5'>
                            <p className='accordion-mark'>{bullet.title}</p>
                            <div className='accordion-body'>
                              <p className='is-margin-bottom-4'>
                                {bullet.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      <p>{course.plan.abc.suffix}</p>
                    </div>
                  </div>
                </ScrollOnAnimation>
                <ScrollOnAnimation>
                  <div className='column is-10-mobile is-8-tablet is-5-desktop is-offset-1-desktop'>
                    <GatsbyImage
                      image={getImage(image4)}
                      title={course.plan.abc.image.title}
                      alt={course.plan.abc.image.alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(image4).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {course.plan.abc.image.title}
                      </p>
                    </div>
                  </div>
                </ScrollOnAnimation>
              </div>
            </div>
            <div className='extras-item'>
              <ScrollOnAnimation>
                <div className='has-text-centered is-margin-bottom-6'>
                  <h4>{course.plan.warmup.title}</h4>
                  <p>{course.plan.warmup.description}</p>
                </div>
              </ScrollOnAnimation>
              <div className='columns is-multiline is-mobile is-centered is-vcentered direction-row-reverse'>
                <ScrollOnAnimation>
                  <div className='column is-6-desktop is-full-touch is-offset-1-desktop'>
                    <div className='accordion is-fixed-on-open'>
                      <input type='checkbox' id='warmup' hidden />
                      <label
                        className='accordion-header is-margin-left-3'
                        htmlFor='warmup'
                      >
                        <a className='button is-text has-text-weight-bold'>
                          {course.plan.expand}
                        </a>
                      </label>
                      {course.plan.warmup.bullets.map((bullet) => (
                        <div key={bullet.title} className='is-relative'>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className='has-text-nude is-size-7 is-absolute'
                            style={{ top: 8 }}
                          />
                          <div className='is-margin-left-5'>
                            <p className='accordion-mark'>{bullet.title}</p>
                            <div className='accordion-body'>
                              <p className='is-margin-bottom-4'>
                                {bullet.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div>
                        <p className='is-margin-top-4 has-text-weight-bold is-underlined'>
                          {course.plan.warmup.suffix.title}
                        </p>
                        <p>{course.plan.warmup.suffix.description}</p>
                      </div>
                    </div>
                  </div>
                </ScrollOnAnimation>
                <ScrollOnAnimation>
                  <div className='column is-10-mobile is-8-tablet is-5-desktop'>
                    <GatsbyImage
                      image={getImage(image6)}
                      title={course.plan.warmup.image.title}
                      alt={course.plan.warmup.image.alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(image6).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {course.plan.warmup.image.title}
                      </p>
                    </div>
                  </div>
                </ScrollOnAnimation>
              </div>
            </div>
            <div className='extras-item'>
              <ScrollOnAnimation>
                <div className='has-text-centered is-margin-bottom-6'>
                  <h4>{course.plan.figures.title}</h4>
                  <p>{course.plan.figures.description}</p>
                </div>
              </ScrollOnAnimation>
              <div className='columns is-multiline is-mobile is-centered is-vcentered'>
                <ScrollOnAnimation>
                  <div className='column is-6-desktop is-full-tablet'>
                    <div className='accordion is-fixed-on-open'>
                      <p>{course.plan.figures.prefix}</p>
                      <input type='checkbox' id='figures' hidden />
                      <label
                        className='accordion-header is-margin-left-3'
                        htmlFor='figures'
                      >
                        <a className='button is-text has-text-weight-bold'>
                          {course.plan.expand}
                        </a>
                      </label>
                      {course.plan.figures.bullets.map((bullet) => (
                        <div key={bullet.title} className='is-relative'>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className='has-text-nude is-size-7 is-absolute'
                            style={{ top: 8 }}
                          />
                          <div className='is-margin-left-5'>
                            <p className='accordion-mark'>{bullet.title}</p>
                            <div className='accordion-body'>
                              <p className='is-margin-bottom-4'>
                                {bullet.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </ScrollOnAnimation>
                <ScrollOnAnimation>
                  <div className='column is-10-mobile is-8-tablet is-5-desktop is-offset-1-desktop'>
                    <GatsbyImage
                      image={getImage(image5)}
                      title={course.plan.figures.image.title}
                      alt={course.plan.figures.image.alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(image5).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {course.plan.figures.image.title}
                      </p>
                    </div>
                  </div>
                </ScrollOnAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='container content has-text-centered'>
              <ScrollOnAnimation>
                <div className='is-margin-bottom-6'>
                  <h3>{course.plan.tutorials.title}</h3>
                  <p>{course.plan.tutorials.description}</p>
                </div>
              </ScrollOnAnimation>
            </div>
            <div className='columns is-multiline is-mobile is-centered'>
              <ScrollOnAnimation>
                <div className='column is-12-mobile is-8-tablet is-6-desktop'>
                  <div className='has-text-centered'>
                    <h4>{course.plan.tutorials.photos.title}</h4>
                  </div>
                  <p>{course.plan.tutorials.photos.description}</p>
                </div>
              </ScrollOnAnimation>
              <ScrollOnAnimation>
                <div className='column is-5-desktop is-offset-1-desktop is-8-tablet is-12-mobile'>
                  <div className='has-text-centered'>
                    <h4>{course.plan.tutorials.pricing.title}</h4>
                  </div>
                  <p>{course.plan.tutorials.pricing.description}</p>
                </div>
              </ScrollOnAnimation>
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <h3 className='has-text-nude'>{course.howItWorks.title}</h3>
          </div>
        </div>
      </div>
      {[
        course.howItWorks.bullets.slice(0, 3),
        course.howItWorks.bullets.slice(3, 6)
      ].map((bullets, index) => (
        <div key={index} className='hero'>
          <div className='hero-body'>
            <div className='container content'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-mobile is-8-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  {bullets.map((bullet) => (
                    <ScrollOnAnimation key={bullet.title}>
                      <div className='columns is-flex-direction-column-touch'>
                        <div className='column is-narrow has-text-centered-touch is-padding-bottom-0'>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className='has-text-nude is-size-7 is-relative'
                            style={{ top: 4 }}
                          />
                        </div>
                        <div className='column'>
                          <h4 className='has-text-centered-touch'>
                            {bullet.title}
                          </h4>
                          <p>{bullet.description}</p>
                        </div>
                      </div>
                    </ScrollOnAnimation>
                  ))}
                </div>
                <ScrollOnAnimation>
                  <div
                    className={clsx(
                      'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen is-margin-top-4-touch',
                      {
                        'is-offset-1-widescreen': index % 2 === 0
                      }
                    )}
                  >
                    <GatsbyImage
                      image={getImage(HOW_IT_WORKS_IMAGE[index])}
                      title={course.howItWorks.images[index].title}
                      alt={course.howItWorks.images[index].alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(HOW_IT_WORKS_IMAGE[index]).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {course.howItWorks.images[index].title}
                      </p>
                    </div>
                  </div>
                </ScrollOnAnimation>
              </div>
            </div>
          </div>
        </div>
      ))}
      <ScrollOnAnimation>
        <div className='hero has-background-nude-lighter'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <FontAwesomeIcon
                icon={faAward}
                className='has-text-nude is-margin-bottom-4'
                size='2x'
              />
              <div className='is-margin-bottom-4'>
                <h3>{course.responsibility.title}</h3>
              </div>
              <p className='is-margin-bottom-6'>
                {course.responsibility.description}
              </p>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div id='registration' className='hero has-background-nude-light'>
          <div className='hero-body'>
            <div className='container has-text-centered content'>
              <h3>{course.pricing.title}</h3>
              <div className='has-text-centered is-family-secondary block'>
                <span className='is-block is-size-5 has-text-weight-bold'>
                  {prettyPrice(course.pricing.paymentPrice)}{' '}
                  {i18n.courses.currency}
                </span>
              </div>
              <p className='has-text-weight-bold is-margin-bottom-6'>
                {course.pricing.suffix}
              </p>
              <button
                className='button is-nude is-medium'
                onClick={() => setIsPaymentForm(true)}
              >
                {course.pricing.callToAction}
              </button>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div className='hero has-background-nude-lighter'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns is-mobile is-multiline is-centered'>
                <div className='column content is-margin-bottom-0 is-full-mobile is-10-tablet is-6-desktop'>
                  <h3>{course.about.title}</h3>
                  <p>{course.about.description}</p>
                </div>
                <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen is-order-3-touch'>
                  <GatsbyImage
                    image={getImage(survival)}
                    title={course.about.mainImage.title}
                    alt={course.about.mainImage.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(survival).width,
                      margin: '0 auto' // used to center the image
                    }}
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {course.about.mainImage.title}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      {course.testimonials && (
        <>
          <div className='hero'>
            <div className='hero-body'>
              <div className='container content'>
                <div className='has-text-centered is-margin-bottom-6'>
                  <h3>{course.testimonials.title}</h3>
                </div>
                {course.testimonials.testimonials.map((testimony, index) => (
                  <div key={testimony.title} className='columns is-centered'>
                    <div className='column is-6-widescreen is-8-desktop is-10-tablet is-12-mobile'>
                      <div
                        className={clsx(
                          'columns is-flex-direction-column is-mobile is-align-items-center',
                          { 'direction-column-reverse': testimony.isEnglish }
                        )}
                      >
                        <div className='column is-align-self-flex-start'>
                          <FontAwesomeIcon icon={faQuoteRight} size='lg' />
                        </div>
                        <div
                          className='column is-padding-top-0 is-padding-bottom-0'
                          dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                        >
                          <h4 className='has-text-gold-black is-margin-bottom-0'>
                            {testimony.title}
                          </h4>
                        </div>
                        <div className='column is-align-self-flex-end'>
                          <FontAwesomeIcon icon={faQuoteLeft} size='lg' />
                        </div>
                      </div>
                      <div
                        className={clsx('columns is-align-items-start', {
                          'direction-row-reverse': testimony.isEnglish
                        })}
                      >
                        <div className='column is-narrow'>
                          <p className='has-text-centered has-text-weight-bold'>
                            {testimony.name}
                          </p>
                        </div>
                        <div className='column'>
                          <p
                            className='is-margin-bottom-0'
                            dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                          >
                            {testimony.text}
                          </p>
                        </div>
                      </div>
                      {index < course.testimonials.testimonials.length - 1 && (
                        <hr />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='hero'>
            <div className='hero-body has-text-centered content'>
              <a href='#registration' className='button is-nude is-medium'>
                {course.callToAction}
              </a>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const CakesYouCannotIgnore = ({ location: { pathname, search } }) => {
  const data = useStaticQuery(WowQuery)
  return (
    <Layout>
      <Seo
        metadata={course.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.image1),
          alt: course.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            {
              name: course.name,
              path: '/academy/cakes-you-cannot-ignore/'
            }
          ]
        }}
      />
      <CakesYouCannotIgnoreTemplate
        pathname={pathname}
        search={search}
        data={data}
      />
    </Layout>
  )
}

export default CakesYouCannotIgnore
